export const useDate = (rawtime?: Date) => {
  const today = new Date()

  const tomorrow = () => {
    const date = new Date(today)
    date.setDate(date.getDate() + 1)
    return date
  }

  const date = new Date(rawtime)
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hh = date.getHours()
  const mm = date.getMinutes()

  return {
    today,
    tomorrow,
    date,
    year,
    month,
    day,
    hh,
    mm
  }
}
