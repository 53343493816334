import VueHotjar from 'vue-hotjar-next'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { hotjar, nodeEnv }
  } = useRuntimeConfig()
  const id = Number(hotjar.id)
  const snippetVersion = Number(hotjar.snippetVersion)

  nuxtApp.vueApp.use(VueHotjar, {
    id,
    isProduction: nodeEnv !== 'development',
    snippetVersion
  })
})
