import { type AuthUser } from '~~/stores/auth/types'

export const useAuthStorage = (
  { authTokenKey, authUserKey, authEmailKey, authUserDetail } = {
    authTokenKey: 'token',
    authUserKey: 'user',
    authEmailKey: 'email',
    authUserDetail: 'user.detail'
  }
) => {
  const user = useCookie<AuthUser>(authUserKey)
  const token = useCookie<string>(authTokenKey)
  const email = useCookie<string>(authEmailKey)
  const userDetail = useCookie<AuthUser>(authUserDetail)
  // const hasOnboarding =

  const store = (
    newToken: string,
    newUser?: Record<string, any> | null,
    newEmail?: string
  ) => {
    token.value = newToken
    user.value = newUser ?? user
    email.value = newEmail
  }

  const setUserDetail = (newUser?: Record<string, any>) => {
    userDetail.value = newUser
  }

  const clear = () => {
    user.value = null
    token.value = ''
    email.value = ''
    userDetail.value = null
  }

  return { store, clear, user, token, setUserDetail, userDetail }
}
