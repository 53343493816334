import { defineStore } from 'pinia'

import useActions from './actions'
import { type AuthState } from './types'

export const useAuthStore = defineStore('auth', () => {
  const { token, user } = useAuthStorage()
  const state = reactive<AuthState>({
    isLoggedIn: !!token.value,
    user: user.value,
    loading: false
  })

  const actions = useActions(state)
  return {
    state,
    ...actions
  }
})
