import * as validation from './validation'
import * as formatter from './formatter'
import * as image from './image'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      ...validation,
      ...formatter,
      ...image
    }
  }
})
