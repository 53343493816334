import { default as _400bZQ9wmyZFZMeta } from "/usr/src/nuxt-app/pages/400.vue?macro=true";
import { default as _404Am7IRVzZLlMeta } from "/usr/src/nuxt-app/pages/404.vue?macro=true";
import { default as _500G6FYxrlp1HMeta } from "/usr/src/nuxt-app/pages/500.vue?macro=true";
import { default as aboutqNk3HSzUMDMeta } from "/usr/src/nuxt-app/pages/about.vue?macro=true";
import { default as contactys44YCxHo7Meta } from "/usr/src/nuxt-app/pages/contact.vue?macro=true";
import { default as helpyMZjyZoaSzMeta } from "/usr/src/nuxt-app/pages/help.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as indexFr9SG4UmJ0Meta } from "/usr/src/nuxt-app/pages/kategori/index.vue?macro=true";
import { default as kebijakan_45privasi3YppW17TppMeta } from "/usr/src/nuxt-app/pages/kebijakan-privasi.vue?macro=true";
import { default as ketentuan_45layanan6SwS27EAqWMeta } from "/usr/src/nuxt-app/pages/ketentuan-layanan.vue?macro=true";
import { default as certificatetsPIFKFNHpMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue?macro=true";
import { default as indexnaUqBzHvFQMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/index.vue?macro=true";
import { default as materiEUAUJnQxBWMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue?macro=true";
import { default as index9eM5UYaVrDMeta } from "/usr/src/nuxt-app/pages/profil/form/index.vue?macro=true";
import { default as indexDypmjvR0HYMeta } from "/usr/src/nuxt-app/pages/profil/index.vue?macro=true";
import { default as pusat_45bantuanjieEWTPEjWMeta } from "/usr/src/nuxt-app/pages/pusat-bantuan.vue?macro=true";
import { default as reset_45emailsjv5ea7BiMMeta } from "/usr/src/nuxt-app/pages/reset-email.vue?macro=true";
import { default as indexgceGhax48pMeta } from "/usr/src/nuxt-app/pages/reset-password/index.vue?macro=true";
import { default as success2TR5GAVatAMeta } from "/usr/src/nuxt-app/pages/reset-password/success.vue?macro=true";
import { default as indexxrMQAuuCc9Meta } from "/usr/src/nuxt-app/pages/riwayat-transaksi/index.vue?macro=true";
import { default as searchiOAa32tjZNMeta } from "/usr/src/nuxt-app/pages/search.vue?macro=true";
import { default as signindVFe89Mb1OMeta } from "/usr/src/nuxt-app/pages/signin.vue?macro=true";
import { default as signuppd0Tj8SUdWMeta } from "/usr/src/nuxt-app/pages/signup.vue?macro=true";
import { default as indexAeP6eqxFklMeta } from "/usr/src/nuxt-app/pages/studi-saya/index.vue?macro=true";
import { default as indexS8IJxfIoRRMeta } from "/usr/src/nuxt-app/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _400bZQ9wmyZFZMeta?.name ?? "400",
    path: _400bZQ9wmyZFZMeta?.path ?? "/400",
    meta: _400bZQ9wmyZFZMeta || {},
    alias: _400bZQ9wmyZFZMeta?.alias || [],
    redirect: _400bZQ9wmyZFZMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _404Am7IRVzZLlMeta?.name ?? "404",
    path: _404Am7IRVzZLlMeta?.path ?? "/404",
    meta: _404Am7IRVzZLlMeta || {},
    alias: _404Am7IRVzZLlMeta?.alias || [],
    redirect: _404Am7IRVzZLlMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500G6FYxrlp1HMeta?.name ?? "500",
    path: _500G6FYxrlp1HMeta?.path ?? "/500",
    meta: _500G6FYxrlp1HMeta || {},
    alias: _500G6FYxrlp1HMeta?.alias || [],
    redirect: _500G6FYxrlp1HMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/500.vue").then(m => m.default || m)
  },
  {
    name: aboutqNk3HSzUMDMeta?.name ?? "about",
    path: aboutqNk3HSzUMDMeta?.path ?? "/about",
    meta: aboutqNk3HSzUMDMeta || {},
    alias: aboutqNk3HSzUMDMeta?.alias || [],
    redirect: aboutqNk3HSzUMDMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactys44YCxHo7Meta?.name ?? "contact",
    path: contactys44YCxHo7Meta?.path ?? "/contact",
    meta: contactys44YCxHo7Meta || {},
    alias: contactys44YCxHo7Meta?.alias || [],
    redirect: contactys44YCxHo7Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: helpyMZjyZoaSzMeta?.name ?? "help",
    path: helpyMZjyZoaSzMeta?.path ?? "/help",
    meta: helpyMZjyZoaSzMeta || {},
    alias: helpyMZjyZoaSzMeta?.alias || [],
    redirect: helpyMZjyZoaSzMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFr9SG4UmJ0Meta?.name ?? "kategori",
    path: indexFr9SG4UmJ0Meta?.path ?? "/kategori",
    meta: indexFr9SG4UmJ0Meta || {},
    alias: indexFr9SG4UmJ0Meta?.alias || [],
    redirect: indexFr9SG4UmJ0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/kategori/index.vue").then(m => m.default || m)
  },
  {
    name: kebijakan_45privasi3YppW17TppMeta?.name ?? "kebijakan-privasi",
    path: kebijakan_45privasi3YppW17TppMeta?.path ?? "/kebijakan-privasi",
    meta: kebijakan_45privasi3YppW17TppMeta || {},
    alias: kebijakan_45privasi3YppW17TppMeta?.alias || [],
    redirect: kebijakan_45privasi3YppW17TppMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/kebijakan-privasi.vue").then(m => m.default || m)
  },
  {
    name: ketentuan_45layanan6SwS27EAqWMeta?.name ?? "ketentuan-layanan",
    path: ketentuan_45layanan6SwS27EAqWMeta?.path ?? "/ketentuan-layanan",
    meta: ketentuan_45layanan6SwS27EAqWMeta || {},
    alias: ketentuan_45layanan6SwS27EAqWMeta?.alias || [],
    redirect: ketentuan_45layanan6SwS27EAqWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/ketentuan-layanan.vue").then(m => m.default || m)
  },
  {
    name: certificatetsPIFKFNHpMeta?.name ?? "kursus-slug-certificate",
    path: certificatetsPIFKFNHpMeta?.path ?? "/kursus/:slug()/certificate",
    meta: certificatetsPIFKFNHpMeta || {},
    alias: certificatetsPIFKFNHpMeta?.alias || [],
    redirect: certificatetsPIFKFNHpMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue").then(m => m.default || m)
  },
  {
    name: indexnaUqBzHvFQMeta?.name ?? "kursus-slug",
    path: indexnaUqBzHvFQMeta?.path ?? "/kursus/:slug()",
    meta: indexnaUqBzHvFQMeta || {},
    alias: indexnaUqBzHvFQMeta?.alias || [],
    redirect: indexnaUqBzHvFQMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: materiEUAUJnQxBWMeta?.name ?? "kursus-slug-materi",
    path: materiEUAUJnQxBWMeta?.path ?? "/kursus/:slug()/materi",
    meta: materiEUAUJnQxBWMeta || {},
    alias: materiEUAUJnQxBWMeta?.alias || [],
    redirect: materiEUAUJnQxBWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue").then(m => m.default || m)
  },
  {
    name: index9eM5UYaVrDMeta?.name ?? "profil-form",
    path: index9eM5UYaVrDMeta?.path ?? "/profil/form",
    meta: index9eM5UYaVrDMeta || {},
    alias: index9eM5UYaVrDMeta?.alias || [],
    redirect: index9eM5UYaVrDMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/profil/form/index.vue").then(m => m.default || m)
  },
  {
    name: indexDypmjvR0HYMeta?.name ?? "profil",
    path: indexDypmjvR0HYMeta?.path ?? "/profil",
    meta: indexDypmjvR0HYMeta || {},
    alias: indexDypmjvR0HYMeta?.alias || [],
    redirect: indexDypmjvR0HYMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/profil/index.vue").then(m => m.default || m)
  },
  {
    name: pusat_45bantuanjieEWTPEjWMeta?.name ?? "pusat-bantuan",
    path: pusat_45bantuanjieEWTPEjWMeta?.path ?? "/pusat-bantuan",
    meta: pusat_45bantuanjieEWTPEjWMeta || {},
    alias: pusat_45bantuanjieEWTPEjWMeta?.alias || [],
    redirect: pusat_45bantuanjieEWTPEjWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/pusat-bantuan.vue").then(m => m.default || m)
  },
  {
    name: reset_45emailsjv5ea7BiMMeta?.name ?? "reset-email",
    path: reset_45emailsjv5ea7BiMMeta?.path ?? "/reset-email",
    meta: reset_45emailsjv5ea7BiMMeta || {},
    alias: reset_45emailsjv5ea7BiMMeta?.alias || [],
    redirect: reset_45emailsjv5ea7BiMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/reset-email.vue").then(m => m.default || m)
  },
  {
    name: indexgceGhax48pMeta?.name ?? "reset-password",
    path: indexgceGhax48pMeta?.path ?? "/reset-password",
    meta: indexgceGhax48pMeta || {},
    alias: indexgceGhax48pMeta?.alias || [],
    redirect: indexgceGhax48pMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: success2TR5GAVatAMeta?.name ?? "reset-password-success",
    path: success2TR5GAVatAMeta?.path ?? "/reset-password/success",
    meta: success2TR5GAVatAMeta || {},
    alias: success2TR5GAVatAMeta?.alias || [],
    redirect: success2TR5GAVatAMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/reset-password/success.vue").then(m => m.default || m)
  },
  {
    name: indexxrMQAuuCc9Meta?.name ?? "riwayat-transaksi",
    path: indexxrMQAuuCc9Meta?.path ?? "/riwayat-transaksi",
    meta: indexxrMQAuuCc9Meta || {},
    alias: indexxrMQAuuCc9Meta?.alias || [],
    redirect: indexxrMQAuuCc9Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/riwayat-transaksi/index.vue").then(m => m.default || m)
  },
  {
    name: searchiOAa32tjZNMeta?.name ?? "search",
    path: searchiOAa32tjZNMeta?.path ?? "/search",
    meta: searchiOAa32tjZNMeta || {},
    alias: searchiOAa32tjZNMeta?.alias || [],
    redirect: searchiOAa32tjZNMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: signindVFe89Mb1OMeta?.name ?? "signin",
    path: signindVFe89Mb1OMeta?.path ?? "/signin",
    meta: signindVFe89Mb1OMeta || {},
    alias: signindVFe89Mb1OMeta?.alias || [],
    redirect: signindVFe89Mb1OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signuppd0Tj8SUdWMeta?.name ?? "signup",
    path: signuppd0Tj8SUdWMeta?.path ?? "/signup",
    meta: signuppd0Tj8SUdWMeta || {},
    alias: signuppd0Tj8SUdWMeta?.alias || [],
    redirect: signuppd0Tj8SUdWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indexAeP6eqxFklMeta?.name ?? "studi-saya",
    path: indexAeP6eqxFklMeta?.path ?? "/studi-saya",
    meta: indexAeP6eqxFklMeta || {},
    alias: indexAeP6eqxFklMeta?.alias || [],
    redirect: indexAeP6eqxFklMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/studi-saya/index.vue").then(m => m.default || m)
  },
  {
    name: indexS8IJxfIoRRMeta?.name ?? "wishlist",
    path: indexS8IJxfIoRRMeta?.path ?? "/wishlist",
    meta: indexS8IJxfIoRRMeta || {},
    alias: indexS8IJxfIoRRMeta?.alias || [],
    redirect: indexS8IJxfIoRRMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/wishlist/index.vue").then(m => m.default || m)
  }
]