export default defineNuxtPlugin(() => {
  /**
   * Function to remove space on sentence or string
   * @param value string
   * @returns string wothout space
   */
  const whiteSpace = (value: string) => value.replace(/\s/g, '')

  /**
   * Function to check is just only space or have senctence
   * @param value string
   * @returns boolean
   */
  const isOnlySpace = (value: string) => !whiteSpace(value).length

  return {
    provide: {
      whiteSpace,
      isOnlySpace
    }
  }
})
