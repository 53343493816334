export const currencyFormat = (value: number | string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertToHour = (time: number | string) => {
  const secondValue: number = Number(time)
  const hour = Math.floor((secondValue % (3600 * 24)) / 3600)
  const minute = Math.floor((secondValue % 3600) / 60)
  const second = Math.floor(secondValue % 60)
  let timeTraslate = ''

  if (+time === 0 || !time) {
    return 0
  }

  if (hour > 0) {
    timeTraslate += `${hour} jam `
  }

  if (minute > 0) {
    timeTraslate += `${minute} menit `
  }

  if (second > 0) {
    timeTraslate += `${second} detik `
  }

  return timeTraslate
}

/**
 * Function to convert second to time
 * @param {*} value of second
 * @returns string of 00:00:00
 */
export const secondToTime = (value: number | string) => {
  const secondValue: number = Number(value)
  const hour = Math.floor((secondValue % (3600 * 24)) / 3600)
  const minute = Math.floor((secondValue % 3600) / 60)
  const second = Math.floor(secondValue % 60)

  const hourDisplay = hour < 10 ? `0${hour}` : hour
  const minuteDisplay = minute < 10 ? `0${minute}` : minute
  const secondDisplay = second < 10 ? `0${second}` : second

  return `${hourDisplay}:${minuteDisplay}:${secondDisplay}`
}

export const formatNumberOnly = (val: string, preVal: string) => {
  const reg = /^-?\d*(\.\d*)?$/

  if ((!isNaN(+val) && reg.test(val)) || val === '' || val === '-') {
    return val
  }

  return preVal
}
