import { defineStore } from 'pinia'
import StdErrorMapping from './std-error-mapping'

export type MessageType = {
  title?: string
  body?: string
  type?: string
  timeout?: number
}
export type MessageState = {
  params?: MessageType
}

export const useMessageStore = defineStore({
  id: 'message',
  state: (): MessageState => {
    return {
      params: {
        title: '',
        body: '',
        type: 'info',
        timeout: 2
      }
    }
  },
  actions: {
    triggerError(statusCode, name?: string, msg?: string) {
      if (msg) {
        return msg
      }

      let message = name || ''

      switch (true) {
        case statusCode === 400:
          message += ': Opps...! Bad Request'
          break
        case statusCode === 401:
          message += ': Opps...! Unauthorized!'
          break
        case statusCode === 403:
          message += ': Opps...! Forbidden!'
          break
        case statusCode === 404:
          message += ': API Endpoint Not Found.!'
          break
        case statusCode === 405:
          message += ': Opps...! Method not allowed'
          break
        case statusCode === 422:
          message +=
            ': Opps...! Data yang anda masukkan salah silahkan check lagi.'
          break
        case statusCode === 429:
          message += ': Opps...! Terlalu banya request ke API!'
          break
        case statusCode === 500:
          message += ': Internal Server Error !'
          break
        case statusCode === 502:
          message += ': Bad Gateway !'
          break
        default:
          message += ': Opps...! Something wrong.'
          break
      }

      this.triggerMessage({
        title: statusCode,
        body: message,
        type: 'error'
      })
    },
    notifyStdError(errorCode: string | number) {
      const msg = StdErrorMapping[errorCode]
      if (!msg) {
        return this.triggerMessage({
          type: 'error',
          body: StdErrorMapping.default
        })
      }

      this.triggerMessage({
        type: 'error',
        body: StdErrorMapping[errorCode]
      })
    },
    triggerMessage(data: MessageType) {
      this.params = { ...this.params, ...data }
    }
  }
})
