<script setup lang="ts">
import { useAuthStore } from './stores/auth'
import { useMessageStore } from './stores/message'

const messageStore = useMessageStore()
const authStore = useAuthStore()
const config = useRuntimeConfig()

const messageParams = computed(() => messageStore?.params)
const meta = computed(() => authStore.state.meta)

const facebookEnv: Record<string, any> = config.public.facebook

useHead({
  titleTemplate: authStore.getTitle(),
  htmlAttrs: {
    lang: 'id'
  }
})

useSeoMeta({
  title: 'Kognisi.id | Kursus & Kelas Online Bersertifikat Terbaik',
  ogTitle: 'Kognisi.id | Kursus & Kelas Online Bersertifikat Terbaik',
  description:
    'Kognisi.id adalah marketplace untuk kursus online di Indonesia yang menawarkan sertifikasi dengan beragam kategori kursus yang dapat dipelajari sesuai dengan jadwal Anda.',
  ogDescription:
    'Kognisi.id adalah marketplace untuk kursus online di Indonesia yang menawarkan sertifikasi dengan beragam kategori kursus yang dapat dipelajari sesuai dengan jadwal Anda.',
  ogImage:
    'https://mykg-dev-storage.sgp1.digitaloceanspaces.com/cms/growthpath/banner/banner_1690467676.png',
  ogImageAlt: 'Kognisi.id',
  ogImageUrl:
    'https://mykg-dev-storage.sgp1.digitaloceanspaces.com/cms/growthpath/banner/banner_1690467676.png',
  ogType: 'website'
})
</script>
<template>
  <div>
    <Head>
      <Link rel="preconnect" href="https://fonts.googleapis.com" />
      <Link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
      <Link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <Link
        href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Head>
    <div class="kg">
      <AtomsToasts :params="messageParams" />
      <NuxtPage />
    </div>
  </div>
</template>
