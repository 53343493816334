import { useMessageStore } from '../message'
import {
  type AuthState,
  type DataLoginSSOType,
  type DataLoginType,
  type DataRegisterType,
  type DataRequestPasswordResetType,
  type DataResetPasswordType
} from './types'
import { type ActionParams } from 'types/storeTypes'

const useActions = (state: AuthState) => {
  const { handleErrorInfo } = useCustomFetch()
  const messageStore = useMessageStore()
  const authStorage = useAuthStorage()

  const { API_URL } = useBaseUrl()

  const login = async ({
    payload,
    resolve = () => null
  }: ActionParams<DataLoginType>) => {
    const { data, error, pending } = await useAsyncData('login', () =>
      $fetch(`${API_URL}/login`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'login')
    } else {
      const response = useResult(data?.value, 'data', null)
      const token = response?.token
      const email = response?.email
      const { setCookies } = useCookiesServices()
      setCookies(response)

      state.isLoggedIn = !!token
      state.user = response
      authStorage.store(token, response, email)
      resolve()
    }
  }
  const loginSSO = async ({
    payload,
    resolve = () => null
  }: ActionParams<DataLoginSSOType>) => {
    const { data, error, pending } = await useAsyncData('loginSSo', () =>
      $fetch(`${API_URL}/sso`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'loginSSO')
    }

    const loggedinData = useResult(data?.value, 'data', null)
    const token = loggedinData?.token
    const email = loggedinData?.email

    state.isLoggedIn = !!token
    state.user = loggedinData
    authStorage.store(token, loggedinData, email)
    resolve()
  }

  const register = async ({
    payload,
    resolve = () => null
  }: ActionParams<DataRegisterType>) => {
    const { data, error, pending } = await useAsyncData('register', () =>
      $fetch(`${API_URL}/register`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'register')
    }

    const loggedinData = useResult(data?.value, 'data', null)
    const token = loggedinData?.token
    const email = loggedinData?.email

    state.isLoggedIn = !!token
    state.user = loggedinData
    authStorage.store(token, loggedinData, email)
    resolve()
  }

  const requestPasswordReset = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<DataRequestPasswordResetType>) => {
    const { data, error, pending } = await useAsyncData('resetPassword', () =>
      $fetch(`${API_URL}/reset-password`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'requestPasswordReset')
    }

    resolve()
  }

  const resetPassword = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<DataResetPasswordType>) => {
    const { data, error, pending } = await useAsyncData(
      'updateUserPassword',
      () =>
        $fetch(`${API_URL}/update-user-password`, {
          method: 'POST',
          body: payload
        })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      reject()
      handleErrorInfo(error, 'resetPassword')
    }

    resolve()
  }
  const logout = () => {
    const router = useRouter()
    authStorage.clear()

    state.isLoggedIn = false
    state.user = null

    router.push('/').then(() => {
      if (process.client) {
        window.location.reload()
      }
    })
  }

  const getTitle = (titleChunk: string = '') => {
    return titleChunk ? `Kognisi.id | ${titleChunk}` : state.meta?.title
  }

  const getDescription = (description: string = '') => {
    return description || state.meta?.description
  }

  return {
    login,
    loginSSO,
    register,
    requestPasswordReset,
    resetPassword,
    logout,
    getTitle,
    getDescription
  }
}

export default useActions
