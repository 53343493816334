export const useCookiesServices = () => {
  const { tomorrow } = useDate()
  const _setSpecificCookie = (name, data) => {
    const specific = useCookie(name)
    specific.value = data
  }

  const _getSpecificCookie = (name) => {
    return useCookie(name)
  }

  const _setCookies = (data) => {
    const token = useCookie<string>('token', { expires: tomorrow() })
    const email = useCookie<string>('email', { expires: tomorrow() })

    token.value = useResult(data, 'token')
    email.value = useResult(data, 'email')
  }

  const _setToken = (data) => {
    const token = useCookie<string>('token', { expires: tomorrow() })
    token.value = useResult(data, 'token')
  }

  const _setSession = (data) => {
    const session = useCookie('session', { expires: tomorrow() })
    session.value = useResult(data, 'session')
  }

  const _getAccessToken = () => {
    return useCookie<string>('token')
  }

  const _getSession = () => {
    return useCookie('session')
  }

  const _clearCookie = () => {
    const session = useCookie('session')
    const token = useCookie<string>('token')
    const email = useCookie<string>('email')

    session.value = null
    token.value = ''
    email.value = ''
  }

  return {
    setSpecificCookie: _setSpecificCookie,
    getSpecificCookie: _getSpecificCookie,
    setCookies: _setCookies,
    setToken: _setToken,
    setSession: _setSession,
    getAccessToken: _getAccessToken,
    getSession: _getSession,
    clearCookie: _clearCookie
  }
}
